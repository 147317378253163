/* Login.module.css */

.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1a1a1a;
    min-height: 100vh;
}

.loginBox {
    width: 60%;
    max-width: 300px;
    background-color: #333;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loginTitle {
    font-size: 24px;
    font-weight: bold;
    color: #ff5a5f;
    margin-bottom: 20px;
    text-align: center;
}

.input {
    height: 50px;
    background-color: #333;
    border-radius: 5px;
    padding: 0 10px;
    margin-bottom: 20px;
    color: #fff;
    width: 100%;
    border: 1px solid #ff5a5f;
}

.button {
    width: 100%;
    background-color: #ff5a5f;
    padding: 10px;
    border-radius: 5px;
    margin-top: 15px;
    color: #fff;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
}

.button:hover {
    background-color: #ff7b7b;
}

.errorText {
    color: red;
    margin-bottom: 10px;
    text-align: center;
}

.createAccountText {
    color: #ff5a5f;
    font-size: 16px;
    text-align: center;
    text-decoration: underline;
    margin-top: 20px;
    cursor: pointer;
}

.createAccountText:hover {
    opacity: 0.5;
}