/* Home.module.css */

.container {
    display: flex;
    flex-direction: column;
    background-color: #1a1a1a;
    min-height: 100vh;
}

.headerContainer {
    background-color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    height: 80px;
}

.headerTitle {
    color: #ff5a5f;
    font-size: 20px;
    font-weight: bold;
}

.headerButton {
    padding: 10px;
    background-color: #444;
    border: 1px solid #ff5a5f;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
}

.headerButtonText {
    color: #ff5a5f;
    font-size: 16px;
    cursor: pointer;
}

.headerButton:hover .headerButtonText {
    color: #1a1a1a; /* Black text on hover */
}

.headerButton:hover {
    background-color: #ff5a5f; /* Red background on hover */
}

.toolbar {
    display: flex;
    align-items: center;
    background-color: #444;
    padding: 10px 15px;
}

.menuButton {
    padding: 10px;
    margin-right: 20px;
    background-color: #444;
    border-radius: 5px;
    transition: background-color 0.2s, transform 0.2s, color 0.2s;
    cursor: pointer;
    border: 1px solid transparent;
}

.menuButtonText {
    color: #ff5a5f; /* Red text by default */
    font-size: 16px;
}

.menuButton:hover .menuButtonText {
    color: #1a1a1a; /* Black text on hover */
}

.menuButtonHover {
    background-color: #ff5a5f;
    color: #1a1a1a;
    border-color: #ff5a5f;
    transform: scale(1.05);
}

.adminMenuDropdown {
    position: absolute;
    background-color: #1a1a1a; /* Dark background for submenu */
    border: 1px solid #444; /* Border to separate it from the page */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    z-index: 1000;
    padding: 10px;
    border-radius: 5px; /* Rounded corners */
    color: #fff; /* White text color */
    margin-top: 5px; /* Offset slightly below the button */
}


.heroSection {
    padding: 20px;
}

.heroTitle {
    font-size: 28px;
    color: #fff;
    font-weight: bold;
    margin-bottom: 10px;
}

.heroSubtitle {
    font-size: 18px;
    color: #ddd;
    margin-bottom: 20px;
}

.buttonText {
    color: #fff;
    font-size: 16px;
    text-align: center;
}

.footer {
    padding: 20px;
    background-color: #333;
    text-align: center;
}

.footerText {
    color: #fff;
    font-size: 14px;
}

.contentContainer {
    padding-bottom: 20px;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    flex: 1;
}

.contentText {
    color: #fff;
    font-size: 18px;
}

.menuDropdown {
    position: absolute;
    background-color: #1a1a1a;
    border: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    margin-top: 5px;
}

.menuItem {
    background-color: #333;
    color: #ff5a5f;
    border: none;
    padding: 10px 15px;
    text-align: left;
    width: 100%;
    margin-bottom: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    border-radius: 3px;
}

.menuItem:hover {
    background-color: #ff5a5f;
    color: #1a1a1a; /* Black text on hover */
}

.menuItemText {
    font-size: 16px;
    color: inherit; /* Inherit the color from the parent */
}

.downArrow {
    font-size: 12px;
    margin-left: 5px;
    color: #ff5a5f; /* Matching the color scheme */
}