.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Center horizontally */
    padding: 20px;
    background-color: #1a1a1a;
    min-height: 100vh;
}

.form {
    width: 100%;
    max-width: 400px; /* Set a max width for the form */
    background-color: #1a1a1a;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.inputContainer {
    margin-bottom: 20px;
}

.label {
    color: #fff;
    margin-bottom: 5px;
}

.input {
    height: 50px;
    background-color: #333;
    border-radius: 5px;
    padding: 0 10px;
    color: #fff;
    border: 1px solid #ff5a5f;
    width: 100%;
    box-sizing: border-box;
}

.button {
    height: 50px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    background-color: #ff5a5f;
    color: #fff;
    font-size: 16px;
    transition: opacity 0.3s;
    width: 100%;
}

.button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
}

.cancelButton {
    background-color: #444;
    margin-top: 10px;
}

.cancelButton:hover {
    background-color: #555;
}

.errorText {
    color: red;
    margin-top: 10px;
    text-align: center;
}

.valid {
    color: green;
    margin-top: 5px;
}

.invalid {
    color: red;
    margin-top: 5px;
}