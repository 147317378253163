.container {
    padding: 20px;
    background-color: #1a1a1a;
    color: #fff;
    border-radius: 10px;
}

.title {
    font-size: 20px;
    margin-bottom: 20px;
}

.playerList {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
}

.playerItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #333;
    border-radius: 5px;
}

.input {
    width: 100px;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ff5a5f;
    background-color: #444;
    color: #fff;
}

.button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #ff5a5f;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #e04a4a;
}

.errorText {
    color: red;
    margin-top: 10px;
    text-align: center;
}