.container {
    max-width: 4000px; /* Double the max-width for a much wider layout */
    margin: 0 auto;
    padding: 20px;
    background-color: #1a1a1a;
    color: #fff;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
}

.createSeasonContainer {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.input {
    flex: 1;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #333;
    background-color: #333;
    color: #fff;
}

.button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #ff5a5f;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #e04a4a;
}

.errorText {
    color: red;
    margin-bottom: 10px;
    text-align: center;
}

.contentContainer {
    display: flex;
    gap: 60px; /* Increase space between list and summary */
    flex: 1;
}

.seasonListContainer {
    flex: 5; /* Increase width of the seasons list */
    margin-bottom: 20px;
}

.subtitle {
    font-size: 18px;
    margin-bottom: 10px;
}

.seasonList {
    list-style-type: none;
    padding: 0;
}

.seasonItem {
    width: 200px; /* Set a fixed width for the season list items */
    padding: 20px; /* Increase padding */
    border: 1px solid #333;
    border-radius: 5px;
    background-color: #333;
    margin-bottom: 20px; /* Increase margin between items */
    cursor: pointer;
    transition: background-color 0.3s;
    text-align: center; /* Center the text inside the button */
}

.seasonItem:hover {
    background-color: #444;
}

.seasonSummaryContainer {
    flex: 6; /* Increase the width of the summary container */
    padding: 40px; /* Increase padding */
    border-radius: 5px;
    background-color: #333;
    max-height: 700px; /* Control the height */
    overflow-y: auto;
    align-self: flex-start;
}

.footer {
    margin-top: auto; /* Push footer to bottom */
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
}

.buttonContainer {
    display: flex;
    gap: 10px; /* Adds space between the buttons */
}

.button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #ff5a5f;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #e04a4a;
}