.container {
    max-width: 1200px;
    width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #1a1a1a;
    color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #ff5a5f;
}

.formGroup {
    margin-bottom: 15px;
}

.label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #ff5a5f;
}

.input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #333;
    background-color: #333;
    color: #fff;
}

.button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    background-color: #ff5a5f;
    color: #fff;
    cursor: pointer;
}

.button:hover {
    background-color: #e04a4a;
}

.profileSeasonContainer {
    display: flex;
    gap: 40px;
    margin-top: 20px;
}

.profileFields {
    flex: 1;
}

.tabContainer {
    flex: 3;
    background-color: #2a2a2a;
    border-radius: 8px;
    margin-left: 20px;
    padding: 0; /* Remove padding to eliminate extra space */
    display: flex;
    flex-direction: column;
}

.tabHeaders {
    display: flex;
    justify-content: space-around;
    background-color: #444;
    border-radius: 8px 8px 0 0; /* Rounded corners for the top */
}

.tabHeader {
    flex: 1;
    padding: 10px;
    cursor: pointer;
    background-color: #444;
    color: white;
    border: none;
    border-radius: 8px 8px 0 0; /* Round the corners of the top tabs */
    text-align: center;
}

.activeTab {
    background-color: #ff5a5f;
}

.tabContent {
    flex: 1;
    padding: 20px;
    border-radius: 0 0 8px 8px; /* Rounded corners for the bottom */
    background-color: #333;
    display: flex;
    flex-direction: column;
}

.seasonContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 20px; /* Add padding for spacing inside the container */
    background-color: #444;
    border-radius: 8px;
}

.seasonContainer h3 {
    text-align: center;
    margin-top: 0; /* Remove any margin from the top */
}

.seasonContainer p {
    text-align: left;
    margin: 20px 0; /* Ensure space between elements */
}

.button {
    margin-top: auto;
}