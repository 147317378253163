.tabsContainer {
    display: flex;
    flex-direction: row;
    background-color: #1a1a1a;
    padding: 10px;
    height: 100vh;
}

.tabs {
    display: flex;
    flex-direction: column;
    background-color: #333;
    padding: 10px;
    border-radius: 5px;
    width: 225px;
}

.tab {
    padding: 15px;
    cursor: pointer;
    color: #ff5a5f;
    border-bottom: 2px solid transparent;
    transition: background-color 0.3s, border-bottom 0.3s;
}

.tab:hover {
    background-color: #444;
}

.activeTab {
    font-weight: bold;
    border-bottom: 2px solid #ff5a5f;
    background-color: #444;
}

.tabContent {
    flex: 1;
    padding: 20px;
    background-color: #222;
    margin-left: 20px;
    border-radius: 5px;
    color: #fff;
}

.badge {
    background-color: #ff5a5f;
    color: white;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 12px;
    margin-left: 8px;
}