/* News.module.css */

.container {
    background-color: #1a1a1a;
    padding: 10px;
    min-height: 100vh;
}

.title {
    font-size: 24px;
    font-weight: bold;
    color: #ff5a5f;
    margin-bottom: 20px;
    text-align: center;
}

.newsList {
    margin-top: 20px;
}

.newsItem {
    background-color: #333;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.newsTitle {
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 5px;
}

.newsContent {
    font-size: 16px;
    color: #ddd;
}

.loadingText {
    color: #fff;
    text-align: center;
    margin-top: 20px;
}