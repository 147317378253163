.container {
    display: flex;
    flex-direction: row;
    background-color: #1a1a1a;
    padding: 10px;
    height: 100vh; /* Full height */
}

.sidebarContainer {
    width: 35%;
    align-items: flex-start;
}

.sidebar {
    width: 80%;
    background-color: #333;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.sidebarTitle {
    color: #ff5a5f;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.sidebarStat {
    color: #fff;
    font-size: 16px;
    margin-bottom: 10px;
}

.sidebarLabel {
    font-weight: bold;
}

.mainContent {
    flex: 1;
    padding: 10px;
}

.searchBarContainer {
    display: flex;
    gap: 10px; /* Space between input and button */
    margin-bottom: 20px;
}

.searchBarInput {
    background-color: #444;
    color: #ff5a5f;
    border: 1px solid #ff5a5f;
    padding: 10px;
    border-radius: 5px;
    flex-grow: 1;
}

.searchButton,
.resetButton {
    background-color: #ff5a5f;
    border: none;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.searchButton:hover,
.resetButton:hover {
    background-color: #e04a4a;
}

.table {
    margin-top: 20px;
    border: 1px solid #444;
}

.headerRow {
    display: flex;
    background-color: #333;
    border-bottom: 1px solid #444;
}

.headerCell {
    color: #ff5a5f;
    font-size: 16px;
    font-weight: bold;
    padding: 12px 10px;
    border-right: 1px solid #444;
    text-align: left;
}

.codIdHeader {
    width: 150px;
}

.nameHeader {
    width: 250px;
}

.emailHeader {
    width: 200px;
}

.row {
    display: flex;
    border-bottom: 1px solid #444;
}

.cell {
    color: #fff;
    font-size: 16px;
    padding: 12px 10px;
    border-right: 1px solid #444;
}

.codIdCell {
    width: 150px;
}

.nameCell {
    width: 250px;
}

.emailCell {
    width: 200px;
}

.loadingText,
.noResultsText {
    color: #fff;
    text-align: center;
    margin-top: 20px;
}